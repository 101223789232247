import gql from "gql-tag";
import { ApiConfigs } from "../../types";

export type OperationTemplatesApi =
  | "operationTemplateList"
  | "createOperationTemplate"
  | "updateOperationTemplate"
  | "deleteOperationTemplate"
  | "checkUpdateOperation"
  | "updateOperation";

const OperationTemplateForm = gql`
  fragment OperationTemplateForm on OperationTemplate {
    name
    value
    valueTwo
    minimumTime
    minimumTimeTwo
    comfortTime
    comfortTimeTwo
  }
`;

export const operationTemplates: ApiConfigs<OperationTemplatesApi> = {
  createOperationTemplate: {
    id: "CREATE_OPERATION_TEMPLATE",
    request: (variables) => ({
      method: "POST",
      data: {
        variables,
        query: gql`
          mutation CreateOperationTemplate(
            $input: CreateOperationTemplateInput!
          ) {
            createOperationTemplate(input: $input) {
              id
              ...OperationTemplateForm
            }
          }
          ${OperationTemplateForm}
        `,
      },
    }),
  },

  updateOperationTemplate: {
    id: "UPDATE_OPERATION_TEMPLATE",
    request: (variables) => ({
      method: "POST",
      data: {
        variables,
        query: gql`
          mutation UpdateOperationTemplate(
            $id: Int!
            $input: UpdateOperationTemplateInput!
          ) {
            updateOperationTemplate(id: $id, input: $input) {
              id
              ...OperationTemplateForm
            }
          }
          ${OperationTemplateForm}
        `,
      },
    }),
  },

  deleteOperationTemplate: {
    id: "DELETE_OPERATION_TEMPLATE",
    request: (variables) => ({
      method: "POST",
      data: {
        variables,
        query: gql`
          mutation DeleteOperationTemplate($id: Int!) {
            deleteOperationTemplate(id: $id)
          }
        `,
      },
    }),
  },

  operationTemplateList: {
    id: "OPERATION_TEMPLATE_LIST",
    request: (variables) => ({
      method: "POST",
      data: {
        variables,
        query: gql`
          query OperationTemplateList($input: OperationTemplatesInput) {
            operationTemplates(input: $input) {
              items {
                id
                ...OperationTemplateForm
              }
              filter {
                filter {
                  column
                  operator
                  values
                }
              }
              sorter {
                column
                direction
              }
              pager {
                page
                size
                prev
                next
                last
                total
              }
              search
            }
          }
          ${OperationTemplateForm}
        `,
      },
    }),
  },

  checkUpdateOperation: {
    id: "CHECK_UPDATE_OPERATION",
    request: (variables) => ({
      method: "POST",
      data: {
        variables,
        query: gql`
          query CheckUpdateOperation(
            $id: Int!
            $input: CheckUpdateOperationInput!
          ) {
            checkUpdateOperation(id: $id, input: $input) {
              isPossible
              estimateTo
            }
          }
        `,
      },
    }),
  },

  updateOperation: {
    id: "UPDATE_OPERATION",
    request: (variables) => ({
      method: "POST",
      data: {
        variables,
        query: gql`
          mutation UpdateOperation($id: Int!, $input: UpdateOperationInput!) {
            updateOperation(id: $id, input: $input) {
              id
              estimateTo
            }
          }
        `,
      },
    }),
  },
};
