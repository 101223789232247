import { AdminDashboard } from "../../../types/gqlGeneratedPrivate";

export const notificationsNamespace = "notifications";

export type Notifications = Record<
  keyof Omit<AdminDashboard, "__typename">,
  number
>;

export interface WebsocketNotification {
  content: string;
  parameters: {
    extId: string;
    id: number;
    number: string;
  };
  title: string;
  created: number;
}

export enum NotificationsActions {
  Fetch = "fetch",
  Read = "read",
  AddWebsocketNotification = "addWebsocketNotification",
  FetchMore = "fetchMore",
  Clear = "clear",
}

export enum NotificationsMutations {
  SetReadNotifications = "setReadNotifications",
  SetUnreadNotifications = "setUnreadNotifications",
  SetWebsocketNotifications = "setWebsocketNotifications",
  SetUnreadNotificationsCount = "setUnreadNotificationsCount",
  ClearNotifications = "clearNotifications",
}

export enum NotificationsGetters {
  GetUnreadNotificationsCount = "getUnreadNotificationsCount",
  GetUnreadNotifications = "getUnreadNotifications",
  GetReadNotifications = "getReadNotifications",
  GetWebsocketNotifications = "getWebsocketNotifications",
}
