




import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { AuthGetters, authNamespace } from "../../../store/modules/auth";
import { Admin } from "../../../types/gqlGeneratedPublic";
import {
  NotificationsActions,
  notificationsNamespace,
  WebsocketNotification,
} from "../../../store/modules/notifications";
import { config } from "../../../config";
import { authService } from "../../../utils";
import { eventBus } from "../../../utils/eventBus";
import { EventBus } from "../../../enums";

@Component({
  components: {},
})
export default class GlobalNotificationsHandler extends Vue {
  @Getter(`${authNamespace}/${AuthGetters.GetAdministrator}`)
  admin!: Admin;

  @Action(`${notificationsNamespace}/${NotificationsActions.Fetch}`)
  private loadNotifications!: (payload: { unread: boolean }) => Promise<void>;

  @Action(
    `${notificationsNamespace}/${NotificationsActions.AddWebsocketNotification}`
  )
  private addNewWebsocketNotification!: (
    payload: WebsocketNotification
  ) => Promise<void>;

  socket!: WebSocket;

  initWebsockets() {
    this.socket = new WebSocket(config.backend.websocketBaseUrl);
    this.socket.addEventListener("open", () => {
      this.socket.send(
        JSON.stringify({ type: "auth", token: authService.accessToken })
      );
    });

    // Listen for messages
    this.socket.addEventListener("message", (e) => {
      const content = JSON.parse(e.data);
      if (!Object.hasOwn(content, "data")) {
        return;
      }

      const notification = {
        ...content.data.data,
        created: content.data.created,
      };
      eventBus.$emit(`${EventBus.NewWebsocketNotification}`, notification);
      this.addNewWebsocketNotification(notification);
    });

    this.socket.addEventListener("close", () => {
      if (this.isAdminLoggedIn()) {
        setTimeout(() => this.initWebsockets(), 1000);
      }
    });
  }

  isAdminLoggedIn() {
    // todo loged admin has adminId prop but empty admin has only id prop
    if (this.admin && this.admin.id !== -1) {
      return true;
    }
    return false;
  }

  @Watch("admin")
  async fetchNotifications(): Promise<void> {
    if (!this.isAdminLoggedIn()) {
      if (this.socket) {
        this.socket.close();
      }
      return;
    }

    await this.loadNotifications({ unread: true });
    this.initWebsockets();
  }
}
