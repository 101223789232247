import { admins } from "./private/admins";
import { auth } from "./public/auth";
import { dashboard } from "./private/dashboard";
import { device } from "./private/device";
import { devices } from "./private/devices";
import { laborerEvents } from "./private/laborerEvents";
import { laborers } from "./private/laborers";
import { maintenances } from "./private/maintenances";
import { operationTemplates } from "./private/operationTemplates";
import { processCodes } from "./private/processCodes";
import { processSubCodes } from "./private/processSubCodes";
import { processTemplates } from "./private/processTemplates";
import { regularMaintenances } from "./private/regularMaintenances";
import { scheduler } from "./private/scheduler";
import { ticket } from "./private/ticket";
import { tickets } from "./private/tickets";
import { notifications } from "./private/notifications";
import { settings } from "./private/settings";
import { terminal } from "./terminal/auth";

export const api = {
  terminal,
  admins,
  auth,
  dashboard,
  device,
  devices,
  laborerEvents,
  laborers,
  maintenances,
  operationTemplates,
  processCodes,
  processSubCodes,
  processTemplates,
  regularMaintenances,
  scheduler,
  ticket,
  tickets,
  notifications,
  settings,
};
