import { Assign } from "../types";
import { RouteConfig } from "vue-router";
import { Routes, ViewAuth } from "../enums";

interface RouteConfigExtension {
  name?: Routes;
  meta?: {
    auth: ViewAuth;
  };
}

type ExtendedRouteConfig = Assign<RouteConfig, RouteConfigExtension>;

const terminalRoutes = [
  {
    path: "/terminal/nove-heslo/:token",
    name: Routes.TerminalNewPassword,
    component: () => import("../views/terminal/NewPasswordPage.vue"),
    meta: {
      auth: ViewAuth.Public,
    },
  },
  {
    path: "/terminal/heslo-zmeneno",
    name: Routes.TerminalChangedPassword,
    component: () => import("../views/terminal/ChangedPasswordPage.vue"),
    meta: {
      auth: ViewAuth.Public,
    },
  },
];

const baseRoutes = [
  {
    path: "/",
    redirect: { name: Routes.Dashboard },
  },
  {
    path: "/profil",
    name: Routes.Profile,
    component: () => import("../views/ProfilePage.vue"),
    meta: {
      auth: ViewAuth.Private,
    },
  },
];

const authRoutes = [
  {
    path: "/prihlaseni",
    name: Routes.Login,
    component: () => import("../views/LoginPage.vue"),
    meta: {
      auth: ViewAuth.Public,
    },
  },
  {
    path: "/zapomenute-heslo",
    name: Routes.ForgotPassword,
    component: () => import("../views/ForgotPasswordPage.vue"),
    meta: {
      auth: ViewAuth.Public,
    },
  },
  {
    path: "/reset-hesla",
    name: Routes.ResetPassword,
    component: () => import("../views/ResetPasswordPage.vue"),
    meta: {
      auth: ViewAuth.Public,
    },
  },
  {
    path: "/nove-heslo/:token",
    name: Routes.NewPassword,
    component: () => import("../views/NewPasswordPage.vue"),
    meta: {
      auth: ViewAuth.Public,
    },
  },
  {
    path: "/heslo-zmeneno",
    name: Routes.ChangedPassword,
    component: () => import("../views/ChangedPasswordPage.vue"),
    meta: {
      auth: ViewAuth.Public,
    },
  },
];

const dashboard = {
  path: "/dashboard",
  name: Routes.Dashboard,
  component: () => import("../views/DashboardPage.vue"),
  meta: {
    auth: ViewAuth.Private,
  },
};

const scheduler = {
  path: "/scheduler",
  name: Routes.Scheduler,
  component: () => import("../views/SchedulerPage.vue"),
  meta: {
    auth: ViewAuth.Private,
  },
};

const laborerRoutes = [
  {
    path: "/laboranti",
    name: Routes.Laborers,
    component: () => import("../views/LaborersPage.vue"),
    meta: {
      auth: ViewAuth.Private,
    },
  },
  {
    path: "/laborant/:id/:date?",
    name: Routes.LaborerDetail,
    component: () => import("../views/LaborerDetailPage.vue"),
    redirect: { name: Routes.LaborerDetailTickets },
    children: [
      {
        path: "/laborant/:id/zakazky/:date?",
        name: Routes.LaborerDetailTickets,
        component: () => import("../views/LaborerDetailTicketsPage.vue"),
        meta: {
          auth: ViewAuth.Private,
        },
      },
      {
        path: "/laborant/:id/udalosti",
        name: Routes.LaborerDetailEvents,
        component: () => import("../views/LaborerDetailEventsPage.vue"),
        meta: {
          auth: ViewAuth.Private,
        },
      },
    ],
  },
];

const processManagementRoutes = [
  {
    path: "ukony",
    name: Routes.OperationTemplates,
    component: () => import("../views/OperationTemplatesPage.vue"),
    meta: {
      auth: ViewAuth.Private,
    },
  },
  {
    path: "pod-kody",
    name: Routes.ProcessSubCodes,
    component: () => import("../views/ProcessSubCodesPage.vue"),
    meta: {
      auth: ViewAuth.Private,
    },
  },
  {
    path: "procesy",
    name: Routes.ProcessTemplates,
    component: () => import("../views/ProcessTemplatesPage.vue"),
    meta: {
      auth: ViewAuth.Private,
    },
  },
  {
    path: "prirazeni",
    name: Routes.ProcessCodes,
    component: () => import("../views/ProcessCodesPage.vue"),
    meta: {
      auth: ViewAuth.Private,
    },
  },
];

const processManagement = {
  path: "/sprava-procesu",
  name: Routes.ProcessManagement,
  component: () => import("../views/ProcessManagementPage.vue"),
  redirect: { name: Routes.OperationTemplates },
  children: processManagementRoutes,
};

const serviceRoutes = [
  {
    path: "zarizeni",
    name: Routes.Devices,
    component: () => import("../views/DevicesPage.vue"),
    meta: {
      auth: ViewAuth.Private,
    },
  },
  {
    path: "zarizeni-detail/:id",
    name: Routes.DeviceDetail,
    component: () => import("../views/DeviceDetailPage.vue"),
    meta: {
      auth: ViewAuth.Private,
    },
  },
  {
    path: "servis-uskutecneny",
    name: Routes.MaintenancesCompleted,
    component: () => import("../views/CompletedMaintenancePage.vue"),
    meta: {
      auth: ViewAuth.Private,
    },
  },
  {
    path: "servis-uskutecneny/:id",
    name: Routes.MaintenancesCompletedForm,
    component: () => import("../views/CompletedMaintenanceFormPage.vue"),
    meta: {
      auth: ViewAuth.Private,
    },
  },
  {
    path: "servis-planovany",
    name: Routes.MaintenancesPlanned,
    component: () => import("../views/PlannedMaintenancePage.vue"),
    meta: {
      auth: ViewAuth.Private,
    },
  },
  {
    path: "servis-planovany/:id",
    name: Routes.MaintenancesPlannedForm,
    component: () => import("../views/PlannedMaintenanceFormPage.vue"),
    meta: {
      auth: ViewAuth.Private,
    },
  },
];

const ticketDetail = {
  path: "/zakazka/:id",
  name: Routes.TicketDetail,
  component: () => import("../views/TicketDetailPage.vue"),
  meta: {
    auth: ViewAuth.Private,
  },
};

const service = {
  path: "/sluzby",
  name: Routes.Service,
  component: () => import("../views/ServicesPage.vue"),
  redirect: { name: Routes.Devices },
  children: serviceRoutes,
};

const usersRoutes = [
  {
    path: "/uzivatele",
    name: Routes.Users,
    component: () => import("../views/AdminsPage.vue"),
    meta: {
      auth: ViewAuth.Private,
    },
  },
  {
    path: "/uzivatel/:id",
    name: Routes.UserUpdate,
    component: () => import("../views/AdminUpdatePage.vue"),
    meta: {
      auth: ViewAuth.Private,
    },
  },
];

const settings = {
  path: "/nastaveni",
  name: Routes.Settings,
  component: () => import("../views/SettingsPage.vue"),
  meta: {
    auth: ViewAuth.Private,
  },
};

const notFound = {
  path: "*",
  name: Routes.NotFound,
  component: () => import("../views/NotFoundPage.vue"),
};

export const routes: Array<ExtendedRouteConfig> = [
  ...baseRoutes,
  ...authRoutes,
  ...terminalRoutes,
  dashboard,
  scheduler,
  ...laborerRoutes,
  ...usersRoutes,
  ticketDetail,
  processManagement,
  service,
  notFound,
  settings,
];
