import { Getters } from "../../../types";
import { NotificationsState } from "./state";
import { NotificationsGetters, WebsocketNotification } from "./types";
import { Notification } from "../../../types/gqlGeneratedPrivate";

export const getters: Getters<NotificationsGetters, NotificationsState> = {
  [NotificationsGetters.GetUnreadNotificationsCount](state): number {
    return state.unreadTotalCount;
  },
  [NotificationsGetters.GetReadNotifications](state): Notification[] {
    return state.readNotifications;
  },
  [NotificationsGetters.GetUnreadNotifications](state): Notification[] {
    return state.unreadNotifications;
  },
  [NotificationsGetters.GetWebsocketNotifications](
    state
  ): WebsocketNotification[] {
    return state.websocketNotifications;
  },
};
