export enum EventBus {
  AdminDeleteModal = "ADMIN_DELETE_MODAL",
  AdminCreateModal = "ADMIN_CREATE_MODAL",

  DeviceDeleteModal = "DEVICE_DELETE_MODAL",
  DeviceCreateModal = "DEVICE_CREATE_MODAL",
  DeviceUpdateModal = "DEVICE_UPDATE_MODAL",

  DeviceUpdatedEvent = "DEVICE_UPDATED_EVENT",

  LaborerUpdateModal = "LABORER_UPDATE_MODAL",

  LaborerInviteModal = "LABORER_INVITE_MODAL",

  LaborerEventDeleteModal = "LABORER_EVENT_DELETE_MODAL",
  LaborerEventCreateModal = "LABORER_EVENT_CREATE_MODAL",
  LaborerEventUpdateModal = "LABORER_EVENT_UPDATE_MODAL",

  MaintenanceCreateModal = "MAINTENANCE_CREATE_MODAL",
  MaintenanceDeleteModal = "MAINTENANCE_DELETE_MODAL",

  MaintenanceCompletionModal = "MAINTENANCE_COMPLETION_MODAL",

  OperationsDeleteModal = "OPERATIONS_DELETE_MODAL",
  OperationsCreateModal = "OPERATIONS_CREATE_MODAL",
  OperationsUpdateModal = "OPERATIONS_UPDATE_MODAL",

  ProcessSubCodeDeleteModal = "PROCESS_SUB_CODE_DELETE_MODAL",
  ProcessSubCodeUpdateModal = "PROCESS_SUB_CODE_UPDATE_MODAL",
  ProcessSubCodeCreateModal = "PROCESS_SUB_CODE_CREATE_MODAL",

  ProcessTemplateDeleteModal = "PROCESS_TEMPLATE_DELETE_MODAL",
  ProcessTemplateCreateModal = "PROCESS_TEMPLATE_CREATE_MODAL",
  ProcessTemplateActivateModal = "PROCESS_TEMPLATE_ACTIVATE_MODAL",

  RegularMaintenanceDeleteModal = "REGULAR_MAINTENANCE_DELETE_MODAL",
  RegularMaintenanceCreateModal = "REGULAR_MAINTENANCE_CREATE_MODAL",
  RegularMaintenanceUpdateModal = "REGULAR_MAINTENANCE_UPDATE_MODAL",

  OnTableQuickFilter = "ON_TABLE_QUICK_FILTER",
  SaveFilterModal = "SAVE_FILTER_MODAL",
  UpdateSavedFiltersModal = "UPDATE_SAVED_FILTERS_MODAL",

  NotificationToggleModal = "NOTIFICATION_TOGGLE_MODAL",
  NewWebsocketNotification = "NEW_WEBSOCKET_NOTIFICATION",
  MessageShowModal = "MESSAGE_SHOW_MODAL",

  TicketRefreshDetail = "TICKET_REFRESH_DETAIL",
}
