export enum Routes {
  // Base routes
  NotFound = "NOT_FOUND",

  // Dashboard
  Dashboard = "DASHBOARD",

  // Scheduler
  Scheduler = "SCHEDULER",

  // Auth routes
  Login = "LOGIN",
  Profile = "PROFILE",
  ForgotPassword = "FORGOT_PASSWORD",
  ResetPassword = "RESET_PASSWORD",
  NewPassword = "NEW_PASSWORD",
  ChangedPassword = "CHANGED_PASSWORD",

  // Users
  Users = "USERS",
  UserUpdate = "USER_UPDATE",

  // Labourers
  Laborers = "LABORERS",
  LaborerDetail = "LABORER_DETAIL",
  LaborerDetailTickets = "LABORER_DETAIL_TICKETS",
  LaborerDetailEvents = "LABORER_DETAIL_EVENTS",

  // Process management
  ProcessManagement = "PROCESS_MANAGEMENT",
  OperationTemplates = "OPERATION_TEMPLATES",
  ProcessSubCodes = "PROCESS_SUB_CODES",
  ProcessTemplates = "PROCESS_TEMPLATES",
  ProcessCodes = "PROCESS_CODES",

  // Service
  Service = "SERVICE",
  Devices = "DEVICES",
  DeviceDetail = "DEVICE_DETAIL",

  //Maintenance
  MaintenancesCompleted = "MAINTENANCES_COMPLETED",
  MaintenancesCompletedForm = "MAINTENANCES_COMPLETED_FORM",
  MaintenancesPlannedForm = "MAINTENANCES_PLANNED_FORM",
  MaintenancesPlanned = "MAINTENANCES_PLANNED",

  // Ticket detail
  TicketDetail = "TICKET_DETAIL",

  // Settings
  Settings = "SETTINGS",

  // TERMINAL
  TerminalNewPassword = "TERMINAL_NEW_PASSWORD",
  TerminalChangedPassword = "TERMINAL_CHANGED_PASSWORD",
}
