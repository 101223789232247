import { Notification } from "../../../types/gqlGeneratedPrivate";
import { WebsocketNotification } from "./types";

export interface NotificationsState {
  readNotifications: Notification[];
  unreadNotifications: Notification[];
  websocketNotifications: WebsocketNotification[];
  unreadTotalCount: number;
}

export const createState = (): NotificationsState => {
  return {
    readNotifications: [],
    unreadNotifications: [],
    websocketNotifications: [],
    unreadTotalCount: 0,
  };
};
