import gql from "gql-tag";
import { ApiConfigs } from "../../types";

export type NotificationsApi = "notificationsList" | "updateNotification" | "readAllNotifications";

export const notifications: ApiConfigs<NotificationsApi> = {
  notificationsList: {
    id: "NOTIFICATIONS_LIST",
    request: (variables) => ({
      method: "POST",
      data: {
        variables,
        query: gql`
          query Notifications($input: NotificationsInput) {
            notifications(input: $input) {
              items {
                id
                created
                updated
                title
                content
                data
                laborer {
                  firstname
                  surname
                }
                admin {
                  username
                  firstname
                }
              }
              pager {
                total
              }
            }
          }
        `,
      },
    }),
  },
  updateNotification: {
    id: "READ_NOTIFICATION",
    request: (variables) => ({
      method: "POST",
      data: {
        variables,
        query: gql`
          mutation UpdateNotification($id: Int!) {
            updateNotification(id: $id) {
              id
            }
          }
        `,
      },
    }),
  },
  readAllNotifications: {
    id: "READ_ALL_NOTIFICATIONS",
    request: (variables) => ({
      method: "POST",
      data: {
        variables,
        query: gql`
          mutation readAllNotifications {
            readAllNotifications
          }
        `,
      },
    }),
  },
};
