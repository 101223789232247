









import { Component, Vue } from "vue-property-decorator";
import GlobalAlerts from "./components/commons/alerts/GlobalAlerts.vue";
import GlobalNotificationsHandler from "./components/app/notification/GlobalNotificationsHandler.vue";

@Component({
  components: { GlobalNotificationsHandler, GlobalAlerts },
})
export default class App extends Vue {}
