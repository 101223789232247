import gql from "gql-tag";
import { ApiConfigs } from "../../types";

export type TerminalApi = "setPassword";

export const terminal: ApiConfigs<TerminalApi> = {
  setPassword: {
    id: "SET_PASSWORD",
    request: (variables) => ({
      method: "POST",
      data: {
        query: gql`
          mutation SetPassword($input: SetPasswordInput!) {
            setPassword(input: $input)
          }
        `,
        variables,
      },
    }),
  },
};
