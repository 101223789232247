import gql from "gql-tag";
import { ApiConfigs } from "../../types";

export type SettingsApi = "settings" | "updateDriver" | "updateControl";

export const settings: ApiConfigs<SettingsApi> = {
  settings: {
    id: "SETTINGS",
    request: (variables) => ({
      method: "POST",
      data: {
        variables,
        query: gql`
          query Settings {
            settings {
              driver {
                name
                value
                valueTwo
                minimumTime
                minimumTimeTwo
                comfortTime
                comfortTimeTwo
              }
              control {
                name
                value
                valueTwo
                minimumTime
                minimumTimeTwo
                comfortTime
                comfortTimeTwo
              }
            }
          }
        `,
      },
    }),
  },
  updateDriver: {
    id: "UPDATE_DRIVER_SETTINGS",
    request: (variables) => ({
      method: "POST",
      data: {
        variables,
        query: gql`
          mutation UpdateDriverSettings($input: UpdateOperationTemplateInput!) {
            updateDriverSettings(input: $input) {
              name
              value
              valueTwo
              minimumTime
              minimumTimeTwo
              comfortTime
              comfortTimeTwo
            }
          }
        `,
      },
    }),
  },
  updateControl: {
    id: "UPDATE_CONTROL_SETTINGS",
    request: (variables) => ({
      method: "POST",
      data: {
        variables,
        query: gql`
          mutation UpdateControlSettings(
            $input: UpdateOperationTemplateInput!
          ) {
            updateControlSettings(input: $input) {
              name
              value
              valueTwo
              minimumTime
              minimumTimeTwo
              comfortTime
              comfortTimeTwo
            }
          }
        `,
      },
    }),
  },
};
