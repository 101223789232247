import { api } from "../../../api";
import {
  FilterOperatorEnum,
  MutationUpdateNotificationArgs,
  NotificationFilterEnum,
  NotificationSorterEnum,
  NotificationsQuery,
  NotificationsQueryVariables,
  SorterDirectionEnum,
} from "../../../types/gqlGeneratedPrivate";
import { Actions } from "../../../types";
import { apiClient } from "../../../utils";
import { NotificationsState } from "./state";
import {
  NotificationsActions,
  NotificationsMutations,
  WebsocketNotification,
} from "./types";
import { FETCH_PAGE_SIZE } from "../../../enums";

export const actions: Actions<NotificationsActions, NotificationsState> = {
  async fetch(
    { commit },
    { unread }: { unread: boolean }
  ): Promise<NotificationsState | void> {
    const condition = {
      column: NotificationFilterEnum.Updated,
      operator: unread ? FilterOperatorEnum.Empty : FilterOperatorEnum.NotEmpty,
      values: [],
    };

    const { data } = await apiClient.callGraphqlPrivate<
      NotificationsQuery,
      NotificationsQueryVariables
    >({
      ...api.notifications.notificationsList,
      variables: {
        input: {
          filter: [
            {
              filter: [condition],
            },
          ],
          sorter: [
            {
              column: NotificationSorterEnum.Created,
              direction: SorterDirectionEnum.Descending,
            },
          ],
        },
      },
    });

    if (data?.notifications) {
      const notifications = data.notifications.items;

      if (unread) {
        commit(NotificationsMutations.SetUnreadNotifications, notifications);
        commit(
          NotificationsMutations.SetUnreadNotificationsCount,
          data?.notifications.pager.total
        );
      } else {
        commit(NotificationsMutations.SetReadNotifications, notifications);
      }
    }
  },
  async read({ commit, state }, payload: { id: number }): Promise<void> {
    const { data } = await apiClient.callGraphqlPrivate<
      any,
      MutationUpdateNotificationArgs
    >({
      ...api.notifications.updateNotification,
      variables: payload,
    });

    if (!data.errors) {
      commit(
        NotificationsMutations.SetUnreadNotificationsCount,
        state.unreadTotalCount - 1
      );
    }
  },
  async clear({ commit }): Promise<void> {
    commit(NotificationsMutations.ClearNotifications);
  },
  async addWebsocketNotification(
    { commit, state },
    payload: WebsocketNotification
  ): Promise<void> {
    commit(
      NotificationsMutations.SetUnreadNotificationsCount,
      state.unreadTotalCount + 1
    );
    commit(NotificationsMutations.SetWebsocketNotifications, [
      payload,
      ...state.websocketNotifications,
    ]);
  },
  async fetchMore(
    { commit, state },
    { unread = true, page = 0 }: { unread: boolean; page: number }
  ): Promise<boolean> {
    const condition = {
      column: NotificationFilterEnum.Updated,
      operator: unread ? FilterOperatorEnum.Empty : FilterOperatorEnum.NotEmpty,
      values: [],
    };

    const { data } = await apiClient.callGraphqlPrivate<
      NotificationsQuery,
      NotificationsQueryVariables
    >({
      ...api.notifications.notificationsList,
      variables: {
        input: {
          filter: [
            {
              filter: [condition],
            },
          ],
          pager: {
            page,
            size: FETCH_PAGE_SIZE,
          },
        },
      },
    });

    if (data?.notifications) {
      const notifications = data.notifications.items;

      if (unread) {
        commit(NotificationsMutations.SetUnreadNotifications, [
          ...state.unreadNotifications,
          ...notifications,
        ]);
      } else {
        commit(NotificationsMutations.SetReadNotifications, [
          ...state.readNotifications,
          ...notifications,
        ]);
      }

      return notifications.length >= FETCH_PAGE_SIZE;
    }
    return false;
  },
};
