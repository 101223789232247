import { Mutations } from "../../../types";
import { NotificationsState } from "./state";
import { NotificationsMutations } from "./types";

export const mutations: Mutations<NotificationsMutations, NotificationsState> =
  {
    [NotificationsMutations.SetReadNotifications](state, payload) {
      state.readNotifications = payload;
    },
    [NotificationsMutations.SetUnreadNotifications](state, payload) {
      state.unreadNotifications = payload;
    },
    [NotificationsMutations.SetUnreadNotificationsCount](state, payload) {
      state.unreadTotalCount = payload;
    },
    [NotificationsMutations.SetWebsocketNotifications](state, payload) {
      state.websocketNotifications = payload;
    },
    [NotificationsMutations.ClearNotifications](state) {
      state.readNotifications = [];
      state.unreadNotifications = [];
      state.unreadTotalCount = 0;
    },
  };
